 

@import url("./assets/css/boxicons.min.css");
@import url("./assets/css/bootstrap-icons.css");
@import url("./assets/css/slick.css");
@import url("./assets/css/magnific-popup.css");
@import url("./assets/css/nice-select.css");
@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/animate.css");
@import url('react-modal-video/css/modal-video.css');
@import url('owl.carousel/dist/assets/owl.carousel.css');
@import url('owl.carousel/dist/assets/owl.theme.default.css');
@import url("./assets/css/slick.css");
@import url("./assets/css/style.css");
@import url("./assets/css/responsive.css");

.header-active1 {
    color: #08beab !important;
}
.header-active2 {
    color: #08beab !important;
}
@media only screen and (min-width: 1199px) {
    .main-nav ul li:hover ul.sub-menu {
        display: block !important;
        transition: all linear .3s;
    }
}